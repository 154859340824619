@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html, body{ 
  width:100%;
  font-size: 14px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.fullHeight {
  height: 100vh;
}

.bg-main {
  background-color: white;
}

.cuberLogoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

html {
  overflow: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
}

html::-webkit-scrollbar {
  display: none;
}

.input-group-text {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.MuiTablePagination-selectLabel {
  margin-top: 14px !important;
}
.MuiTablePagination-displayedRows {
  margin-top: 14px !important;
}